const Footer = () => {
    return ( 
        <footer>
            <div>
                Copyright &copy; 2022 Konrad Starczak.
            </div>
            <div>
                All Rights Reserved.
            </div>
        </footer>
     );
}
 
export default Footer;
<footer>
    <div>
        
    </div>
</footer>